import {useContext, useEffect, useRef, useState} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {useAppAllowed} from "./useAppAllowed";
import {getCookieFromString} from "../utils/StringUtilities";
import {askPermission, isPushSupported} from "../pages/UserSettings/NotificationSettings";
import WatsonApi from "../backends/WatsonApi";
import NotificationManager from "../components/notifications/NotificationManager";

const {UAParser} = require("ua-parser-js");

export function usePushEnabled() {
    const [showPushMessage, setShowPushMessage] = useState(false);
    const {interfaceStore, userStore, organizationStore} = useContext(StoreContext);

    function enablePush() {
        setShowPushMessage(false);

        if (isPushSupported() && userStore.user) {
            navigator.serviceWorker.ready
                .then(function (registration) {
                    askPermission()
                        .then(function () {

                            return registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: "BFcZZ6FiITumPysCi_t9B1TANBHATH7r0Y4bKVRomTVRo14k5eNU0L9LhgZ3ogL7xPkGYh-RqtFN14RlnU3rDWg",
                            })

                        })
                        .then(async function (pushSubscription) {
                            console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
                            const client = await WatsonApi();
                            await client.apis.users.users_push_subscription_create({
                                user_pk: userStore.id,
                                data: {
                                    organization_id:organizationStore.currentOrganization.id,
                                    user_id: userStore.id,
                                    subscription_object: pushSubscription,
                                    user_agent: interfaceStore.userAgent,
                                    device_type: new UAParser(interfaceStore.userAgent).getDevice().type || "desktop", // https://github.com/faisalman/ua-parser-js/issues/182
                                },
                            })

                            await client.apis.organizations.organizations_users_partial_update({
                                organization_pk: organizationStore.currentOrganization.id,
                                id: (userStore.user as IUser).id,
                                data: {
                                    notifications: {
                                        phone: (userStore.user as IUser).notifications?.phone,
                                        email: (userStore.user as IUser).notifications?.email,
                                        push: true,
                                    },
                                },
                            })
                            NotificationManager.success("Settings have been updated!");
                        })
                        .catch(function (subscriptionError) {
                            NotificationManager.error(subscriptionError.message);
                        })
                })
        }
    }

    useEffect(() => {
        if (
            !isPushSupported() ||
            !navigator?.serviceWorker ||
            !("ready" in navigator.serviceWorker)
        ) {
            return;
        }

        navigator.serviceWorker.ready
            .then(() => {
                if (
                    interfaceStore.isPWA &&
                    userStore.user &&
                    !userStore.user.notifications?.push
                ) {
                    setShowPushMessage(true);
                }
            })
    }, [])

    return {showPushMessage, enablePush}
}
