import {useContext, useEffect, useRef, useState} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {useAppAllowed} from "./useAppAllowed";
import {getCookieFromString} from "../utils/StringUtilities";

export function usePWAInstall() {
    const [showAlert, setShowAlert] = useState(false);
    const {interfaceStore} = useContext(StoreContext);

    const cookieKey = "webapp";

    const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);
    const appAllowed = useAppAllowed();

    function handleInstalled() {
        setShowAlert(false);
    }

    function handleInstallPrompt(e) {
        deferredPrompt.current = e;
        if (
            interfaceStore.breakpoint === "break-point-xs" &&
            !(
                window.matchMedia('(display-mode: minimal-ui)').matches ||
                window.matchMedia('(display-mode: standalone)').matches // maybe when added to homescreen on iOS?
            ) &&
            !getCookieFromString(cookieKey, document.cookie) &&
            appAllowed
        ) {
            // only show prompt on small screens
            setShowAlert(true);
        }
    }

    useEffect(() => {
        if (interfaceStore.breakpoint === 'break-point-xs' && !interfaceStore.isPWA && !getCookieFromString(cookieKey, document.cookie) && appAllowed) {
            setShowAlert(true);
        }

        window.addEventListener("beforeinstallprompt", handleInstallPrompt);
        window.addEventListener("appinstalled", handleInstalled);

        return () => {
            window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
            window.removeEventListener("appinstalled", handleInstalled);
        }
    }, []);

    return { deferredPrompt, showAlert, setShowAlert }
}
