import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import styles from './styles/WebAppInstallPrompt.module.scss';
import FontAwesome from "../utilities/FontAwesome";
import classNames from 'classnames';
import {setCookie} from "../../utils/StringUtilities";
import {useAppAllowed} from "../../hooks/useAppAllowed";
import {usePWAInstall} from "../../hooks/usePWAInstall";
import {isDeviceIosSafari, isDeviceIosChrome} from "../../utils/SchoolBlocksUtilities";
import {usePushEnabled} from "../../hooks/usePushEnabled";

const cookieKey = "webapp";

export function WebAppInstallPrompt(props) {
    const {organizationStore, interfaceStore, userStore} = useContext(StoreContext);

    // iOS doesn't support web app downloads, but if it's Safari we can instruct how to add to homescreen
    const isIosSafari = isDeviceIosSafari(interfaceStore.userAgent)
    const isIosChrome = isDeviceIosChrome(interfaceStore.userAgent)

    const appAllowed = useAppAllowed();

    const {deferredPrompt, showAlert, setShowAlert} = usePWAInstall();
    const {showPushMessage, enablePush} = usePushEnabled();

    let installButton, title;
    if (appAllowed) {
        title = organizationStore.organization.title;
        installButton = <button onClick={async () => {
            await deferredPrompt?.current?.prompt()
        }}>Install App</button>;
    }

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerPush]: showPushMessage && appAllowed,
        [styles.containerIosSafariPosition]: showAlert && isIosSafari && !isIosChrome && !showPushMessage,
    })

    return <div>
        {showAlert && <div className={containerClassName}>
            <div className={styles.icon}>
                <img src={organizationStore.organization.icon}
                     alt={organizationStore.organization.title}/>
            </div>
            <div>
                <h2>{title}</h2>
                {isIosSafari ? <div>
                    <span>Tap the <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1"
                                       enableBackground="new 0 0 551.13 551.13" height="20"
                                       viewBox="0 0 551.13 551.13" width="40" fill={"#00bfff"}
                                       style={{verticalAlign: 'bottom'}}>
                        <path
                            d="m465.016 172.228h-51.668v34.446h34.446v310.011h-344.457v-310.011h34.446v-34.446h-51.669c-9.52 0-17.223 7.703-17.223 17.223v344.456c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-344.456c0-9.52-7.703-17.223-17.223-17.223z"/>
                        <path
                            d="m258.342 65.931v244.08h34.446v-244.08l73.937 73.937 24.354-24.354-115.514-115.514-115.514 115.514 24.354 24.354z"/>
                    </svg></span>
                    <span>icon and then click <br />"Add to Homescreen"</span>
                </div> :
                <div className={styles.installButton}>
                    {installButton}
                </div>
                }
            </div>
            <button onClick={async () => {
                setShowAlert(false);
                // since on iOS Safari we seemingly can't determine if inside the app or not, we just want to hide it for good
                await setCookie(cookieKey, "ignore", isIosSafari ? 999999 : 3);
            }}>
                <FontAwesome name={"fa-times"} size={"2x"} prefix={"fas"}/>
            </button>
        </div>}
        { (showPushMessage && appAllowed && userStore.id) &&
            <div className={containerClassName}>
                <div>
                    <h2>{title}</h2>
                    <span>Enable Push Notifications</span>
                </div>
                <div>
                    <button onClick={enablePush}>
                        Enable
                    </button>
                </div>
            </div>}
    </div>
}
